import {NgbDate, NgbModal, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import * as Highcharts from 'highcharts';
import {Observable, of} from 'rxjs';

import {DatepickerComponent} from '../datepicker/datepicker.component';
import {RateSeries} from '../../../domain/rate-series';
import {TimeStatPair} from '../../../domain/time-stat-pair';
import {Tou} from "../../../domain/tou";

export abstract class RateSeriesGraphBaseComponent {
  private modalService: NgbModal;
  selectedPointRates: Array<Tou>;
  selectedSeriesName: string;
  selectedPointTime: string;
  selectedPointValue: number;
  startDate;
  endDate;
  options: any = {
    chart: {
      type: 'line',
      style: {
        fontFamily: 'Arial, sans-serif;',
        fontSize: '.8rem'
      }
    },
    title: {
      text:  null
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      },
      line: {
        step: 'left',
        marker: {
          enabled: true
        }
      }
    },
    tooltip: {
      dateTimeLabelFormats: {
        minute: '%A, %b %e %l:%M %P ',
        hour: '%A, %b %e %l:%M %P ',
        day: '%A, %b %e %l:%M %P ',
        week: '%A, %b %e %l:%M %P '
      },
      shared: true,
    },
    xAxis: {
      type: 'datetime',
      plotBands: [{
        from: this.startDate,
        to: this.endDate
      }],
      dateTimeLabelFormats: {
        minute: '%l:%M %P',
        hour: '%l:%M %P'
      }
    },
    yAxis: {
      title: {}
    },
    time: {
      useUTC: true,
    },
    series: [{
      type: 'line',
      colorByPoint: true,
      data: [],
      showInLegend: false
    }]
  };

  protected constructor(modalService: NgbModal) {
    this.modalService = modalService;
    this.selectedPointRates = [];
  }

  initTimeWindow() {
    const minute5 =  moment().minute() -  moment().minute() % 5;
    this.startDate = moment().minutes(minute5).seconds(0).milliseconds(0);
    this.endDate = moment().minutes(minute5).seconds(0).milliseconds(0).add(7, 'days');
  }

  abstract repaintGraph(): void;
  abstract getDatePicker(): DatepickerComponent;

  updateDateRange() {
    this.modalOpen(this.getDatePicker().modalContent);
  }

  modalOpen(content) {
    this.modalService.open(content, {ariaLabelledBy: 'popup-title'}).result.then(() => {
        const dp: DatepickerComponent = this.getDatePicker();
        if (dp.to) {
          this.customRange(dp.from, dp.to, dp.time);
        } else {
          this.customRange(dp.from, dp.from, dp.time);
        }
      }, () => {}
    );
  }

  customRange(start: NgbDate, end: NgbDate, time: NgbTimeStruct) {
    this.startDate = moment(`${start.year}-${start.month}-${start.day}`, 'YYYY-M-D')
      .tz('UTC')
      .hour(time.hour)
      .minute(time.minute);
    this.endDate = moment(`${end.year}-${end.month}-${end.day}`, 'YYYY-M-D')
      .tz('UTC')
      .hour(time.hour)
      .minute(time.minute)
      .add(1, 'days');
    console.log(`Building series from ${this.startDate} to ${this.endDate}`);
    this.repaintGraph();
  }

  protected buildSeries(rateSeriesArr: RateSeries[], units: string, chartId: string, endStamp: boolean) {
    this.options.series = [];
    for (const rateSeries of rateSeriesArr) {
      rateSeries.timeSeries.sort((a, b)  =>
        a.timestamp - b.timestamp
      );

      const series: any[] = [];
      for (const consumptionRate of rateSeries.timeSeries) {
        series.push({
          x: consumptionRate.timestamp,
          y: consumptionRate.value,
        });
      }

      this.options.series.push({
        type: 'line',
        data: series,
        allowPointSelect: true,
        marker: {
          states: {
            select: {
              fillColor: '#0492A1AA',
              lineWidth: 3,
              lineColor: '#0492A1',
              radius: 12
            }
          }
        },
        point: {
          events: {
            click: ($event: Highcharts.PointClickEventObject) => {
              this.pointClick(
                $event.point.series.name,
                {
                timestamp: $event.point.x,
                value: $event.point.y
              });
            }
          }
        },
        name: rateSeries.name
      });
    }
    this.options.tooltip.valueSuffix = ' ' + units;
    this.options.yAxis.title.text = units;
    this.options.plotOptions.line.step = (endStamp ? 'right' : 'left');

    Highcharts.chart(chartId, this.options);
  }

  /**
   * Derived classes can override to get time of use rates.
   */
  getTous(pointDateTime: string): Observable<Array<Tou>> {
    return of([]);  // Default implementation is an empty list.
  }

  private pointClick(seriesName: string, pair: TimeStatPair) {
    this.selectedPointRates = [];
    const pointDateTime = moment.tz(pair.timestamp, 'UTC').toISOString();
    this.getTous(pointDateTime).subscribe( tous => {
      this.selectedPointTime = pointDateTime;
      this.selectedPointValue = pair.value;
      this.selectedSeriesName = seriesName;
      this.selectedPointRates = tous;
    });
  }
}
